import React from 'react'

const  About = () => {
    return (
        <div>
            <h1 className="display-4">About Contact Manager</h1>
            <p1 className="lead">Easy app to manage contacts</p1>
            <p1 className="text-secondary">  (Version 1.0.0)</p1>
        </div>
    )
}
export default About;