import React from 'react'

function NotFound() {
    return (
        <div>
            <h1 className="display-4"><span className="text-danger">404 </span>PAGE NOT FOUND</h1>
            <p1 className="lead">Sorry, this page doesnot exist</p1>
            
        </div>
    );
};
export default NotFound;
